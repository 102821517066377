<template>
  <div class="ecodes-detail">
    <div class="detail-containner">
      <div class="cover">
        <img :src="staticUrl + product.cover" />
      </div>
      <div class="divider"></div>
      <div class="info">
        <div class="name">{{ product.name }}</div>
        <div class="price">
          <span class="label">￥</span>{{ Number(sku.price / 100).toFixed(2) }}
        </div>
      </div>
      <div class="divider"></div>
      <div class="part-title">商品说明</div>
      <div class="product-description">
        <div>1.下单后系统自动秒发卡</div>
        <div>2.因虚拟产品的特殊性，已售出的点卡不可退换货</div>
        <div>3.购买前请注意点卡对应区服是否和您想要充值的账号区服一致</div>
        <div>4.充值过程中如有任何问题，请联系客服</div>
      </div>
      <div class="divider"></div>
      <div class="part-title">商品详情</div>
      <div class="part-sub-title">
        <div class="sub-title-containner">
          <div class="before-decoration"></div>
          <div class="sub-title">点卡用处</div>
          <div class="after-decoration"></div>
        </div>
      </div>
      <div class="ecodes-use-for">
        <img src="../../assets/ecodes01.png" />
        <img src="../../assets/ecodes02.png" />
        <img src="../../assets/ecodes03.png" />
      </div>
      <div class="part-sub-title">
        <div class="sub-title-containner">
          <div class="before-decoration"></div>
          <div class="sub-title">充值方法</div>
          <div class="after-decoration"></div>
        </div>
      </div>
      <div class="redeem-tutorials">
        <img src="../../assets/ecodes04.png" />
        <img src="../../assets/ecodes05.png" />
      </div>
      <div class="buy-btn-wrap">
        <div class="cs" @click="cs">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="0.4rem"
            height="0.4rem"
            viewBox="0 0 20 20"
            version="1.1"
          >
            <path
              fill="#3a3a3a"
              d="M18.7500049,7.37493323 C18.0000547,3.1249823 14.3750317,0 10.0000781,0 C5.62494873,0 2.0000625,3.25000269 1.24997559,7.3749137 C0.499986328,7.99995703 0,9.12500378 0,10.2499724 C0,11.7499631 0.874990723,13.4999556 2.25000684,13.4999556 C3.49996289,13.4999556 3.49996289,12.1249852 3.49996289,10.2499724 C3.49996289,8.49997998 3.49996289,7.12497058 2.62501123,6.99995019 C3.37494189,3.62498572 6.37503564,1.12496863 10.0000781,1.12496863 C13.6249644,1.12496863 16.6250386,3.62498572 17.3750083,6.87502747 C16.5000176,7.12499011 16.5000176,8.37495959 16.5000176,10.1249325 C16.5000176,11.3636519 16.5000176,12.2340485 16.743204,12.8578223 C16.7454306,12.863545 16.7512119,12.8689356 16.7499619,12.8749318 C16.041731,16.2304625 14.0000469,17.6249642 11.9999844,18.1250067 C11.625019,17.6249642 10.8750298,17.2500203 10.0000586,17.2500203 C8.74992676,17.2500203 7.74999316,17.8750245 7.74999316,18.6249906 C7.74999316,19.3750739 8.74992676,20 10.0000586,20 C11.2500146,20 12.2500264,19.3750739 12.2500264,18.6249906 C14.2500107,18.1250067 16.6667383,16.399741 17.1250444,13.2498953 C17.1242632,13.2552664 17.1328375,13.2574734 17.1369196,13.2610672 C17.265339,13.3749352 17.5077052,13.3749352 17.7499932,13.3749352 C19.1249897,13.3749352 20,11.6249232 20,10.124913 C20,9.12500378 19.5,7.99995703 18.7500049,7.37493323 Z"
              id="路径"
            />
          </svg>
          <div class="nav-name">
            客服
          </div>
        </div>
        <div class="buy-btn" @click="skuShow = true">立即购买</div>
      </div>
    </div>
    <div class="skus-mask" v-if="skuShow">
      <div class="containner">
        <div class="blank"></div>
        <div class="skus-wrap">
          <div class="product">
            <img :src="staticUrl + product.cover" />
            <div class="sku">
              <div class="price">
                <span class="currency-label">￥</span>
                {{ sku.price ? Number(sku.price / 100).toFixed(2) : "" }}
              </div>
              <div class="name">{{ "已选 " + sku.name }}</div>
            </div>
            <div class="close-icon-wrap" @click="closeSku">
              <div class="close-icon"></div>
            </div>
          </div>
          <div
            style="font-size: 0.28rem;display:flex;width:7.02rem;height:0.5rem;color: #1F2021;"
          >
            <div>选择面值</div>
          </div>
          <div class="skus">
            <div
              class="sku"
              :class="{
                active: Number(sku.stock) && skuActIdx == idx,
                disabled: !Number(sku.stock),
              }"
              @click="skuSelect(idx)"
              :key="idx"
              v-for="(sku, idx) in skus"
            >
              <div class="name">{{ sku.name }}</div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="buy-number">
            <div class="label">购买数量</div>
            <div class="number">
              <div class="minus" @click="buyNumberOp('-')">
                <div class="op" :class="{ disabled: buyNumber &lt;= 1 }"></div>
              </div>
              <input type="number" v-model="buyNumber" />
              <div class="add" @click="buyNumberOp('+')">
                <div
                  class="op"
                  :class="{ disabled: buyNumber >= sku.stock }"
                ></div>
              </div>
            </div>
          </div>
          <div class="confirm-btn-wrap">
            <div class="confirm-btn" @click="confirm">确定</div>
          </div>
        </div>
      </div>
    </div>
    <loading :display="showLoading"></loading>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
  </div>
</template>
<script>
import Loading from "@/components/Loading"
import Toast from "@/components/Toast"
import debounce from "@/utils/debounce"
import { newOrder, getProductsDetail } from "@/api/eshop"
import { reportError } from "@/utils/log"
import { utils } from "@/utils"

export default {
  name: "Home",
  data() {
    return {
      staticUrl: utils.getStaticUrl() + "eshop/",
      showLoading: false,
      skuShow: false,
      showToast: false,
      toastMsg: "",
      buyNumber: 1,
      product: {
        name: "Switch美服点卡",
        price: 30,
        id: 0,
        cover: "",
      },
      skus: [],
      skuActIdx: 0,
      sku: {
        id: 0,
        name: "",
        price: "",
        stock: 1,
      },
    }
  },
  watch: {
    buyNumber (val, oldVal) {
      if (val < 0) {
        this.buyNumber = 1
      } else if (val > this.sku.stock) {
        this.buyNumber = this.sku.stock
      }
    }
  },
  components: {
    loading: Loading,
    Toast,
  },
  methods: {
    async getProductsDetail() {
      try {
        let res = await getProductsDetail({
          id: this.product.id,
        })
        if (res.data.code == 0) {
          this.product = res.data.product
          this.skus = res.data.skus
          for (let i = 0; i < this.skus.length; i++) {
            if (this.skus[i].stock) {
              this.sku = this.skus[i]
              this.skuActIdx = i
              break
            }
          }
        }
      } catch (error) {
        reportError(error)
      }
    },
    buyNumberOp(op) {
      if (op == "-") {
        if (this.buyNumber > 1) {
          this.buyNumber = this.buyNumber - 1
        }
      } else {
        if (this.buyNumber < this.sku.stock) {
          this.buyNumber = this.buyNumber + 1
        }
      }
    },
    cs() {
      this.$router.push("/customer-service")
    },
    confirm: debounce(async function() {
      this.showLoading = true
      let orderParams = {
        skuId: this.sku.id,
        uuid: localStorage.getItem("uuid"),
        cf: localStorage.getItem("cf"),
        buyNumber: this.buyNumber,
      }
      let res = await newOrder(orderParams)
      this.showLoading = false
      if (res.data.code == 0) {
        this.$router.push(`/eshop/order?orderid=${res.data.order.orderId}`)
      } else {
        this.toastMsg = res.data.message
        this.showToast = true
      }
    }, 200),
    closeSku() {
      this.skuShow = false
    },
    skuSelect(idx) {
      if (this.skus[idx].stock) {
        this.skuActIdx = idx
        this.sku = this.skus[idx]
      }
    },
  },
  created() {
    if (this.$route.query.pid) {
      this.product.id = this.$route.query.pid
      this.getProductsDetail()
    }
  },
}
</script>

<style lang="less" scoped>
.ecodes-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 7.5rem;
  flex-shrink: 0;
  position: relative;
  font-family: PingFangSC-Medium, PingFang SC;
  .cover {
    width: 7.5rem;
    height: 7.5rem;
    background: #deebe9;
    img {
      width: 7.5rem;
      height: 7.5rem;
    }
  }
  .info {
    width: 7.02;
    height: 1.2rem;
    margin-left: 0.24rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .name {
      font-size: 0.32rem;
      color: #333333;
      font-weight: 500;
    }
    .price {
      .label {
        font-size: 0.24rem;
        font-weight: 400;
        color: #000102;
        margin-right: 0.1rem;
      }
      color: #000102;
      font-weight: 500;
      font-size: 0.32rem;
    }
  }
  .divider {
    width: 7.5rem;
    height: 0.12rem;
    background: #f8f8f8;
  }
  .part-title {
    display: flex;
    align-items: center;
    margin-left: 0.24rem;
    height: 0.68rem;
    width: 7.02rem;
    font-weight: 500;
    color: #333333;
    font-size: 0.32rem;
  }
  .product-description {
    margin-top: 0.12rem;
    width: 7.02rem;
    height: 2.16rem;
    font-size: 0.28rem;
    color: #6f6f6f;
    margin-left: 0.24rem;
    line-height: 0.4rem;
  }
  .part-sub-title {
    height: 0.56rem;
    width: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .sub-title-containner {
      width: 2.7rem;
      height: 0.56rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .before-decoration {
        width: 0.62rem;
        height: 0.04rem;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          #171717 100%
        );
      }
      .sub-title {
        font-size: 0.32rem;
        font-weight: 500;
        color: #333333;
      }
      .after-decoration {
        width: 0.62rem;
        height: 0.04rem;
        background: linear-gradient(
          90deg,
          #171717 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
  .ecodes-use-for {
    width: 7.5rem;
    height: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 2.04rem;
      height: 1.18rem;
    }
  }
  .redeem-tutorials {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.8rem;
    img {
      width: 6.6rem;
      height: 3.7rem;
      margin-top: 0.12rem;
    }
  }
  .buy-btn-wrap {
    position: fixed;
    bottom: 0;
    width: 7.5rem;
    display: flex;
    height: 1.3rem;
    align-items: center;
    background: white;
    justify-content: space-around;
    box-shadow: 0px -0.04rem 0.08rem 0px rgba(0, 0, 0, 0.08);
    .cs {
      display: flex;
      flex-direction: column;
      font-size: 0.22rem;
      height: 0.8rem;
      justify-content: space-between;
      svg {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
    .buy-btn {
      cursor: pointer;
      width: 5.76rem;
      height: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.32rem;
      color: #ffffff;
      border-radius: 0.4rem;
      background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
    }
  }
  .skus-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.65);
    .containner {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      .blank {
        flex: 1;
        width: 7.5rem;
      }
      .skus-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 7.5rem;
        background: #ffffff;
        .product {
          margin-top: 0.12rem;
          width: 7.02rem;
          height: 2rem;
          display: flex;
          position: relative;
          align-items: center;
          img {
            width: 1.48rem;
            height: 1.48rem;
          }
          .sku {
            margin-left: 0.18rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 0.28rem;
            height: 0.9rem;
            .price {
              font-size: 0.44rem;
              color: #ff4a4a;
              .currency-label {
                font-size: 0.24rem;
              }
            }
            .name {
              font-size: 0.28rem;
              color: #999999;
            }
          }
          .close-icon-wrap {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;
            width: 0.8rem;
            height: 0.8rem;
            .close-icon {
              width: 0.5rem;
              height: 0.5rem;
              &::before,
              &::after {
                content: "";
                position: absolute; /*方便进行定位*/
                height: 0.35rem;
                width: 0.03rem;
                border-radius: 0.04rem;
                top: 0.1rem;
                right: 0.21rem; /*设置top和right使图像在20*20框中居中*/
                background: rgba(0, 0, 0, 0.5);
              }
              &::before {
                transform: rotate(45deg); /*进行旋转*/
              }
              &::after {
                transform: rotate(-45deg);
              }
            }
          }
        }
        .skus {
          display: flex;
          width: 7.02rem;
          align-items: center;
          background: white;
          flex-flow: row wrap;
          &::-webkit-scrollbar {
            display: none;
          }
          .sku {
            cursor: pointer;
            flex-shrink: 0;
            white-space: nowrap;
            margin-right: 0.18rem;
            margin-bottom: 0.18rem;
            width: 1.58rem;
            height: 0.9rem;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            border-radius: 0.06rem;
            border: 0.02rem solid #e7e7e7;
            background: #f6f7f8;
            box-shadow: 0px 0px 0.08rem 0.04rem rgba(0, 0, 0, 0.05);
            &:nth-child(4) {
              margin-right: 0;
            }
            .name {
              font-size: 0.28rem;
              color: #000102;
            }
            &.active {
              border: 0.02rem solid #53cca8;
              background: #ebfff9;
              border-radius: 0.06rem;
              box-shadow: 0px 0px 0.08rem 0.04 rgba(83, 204, 168, 0.2);
            }
            &.disabled {
              background: #e7e7e7;
              .name {
                color: #959595;
              }
            }
          }
        }
        .divider {
          margin-top: 0.04rem;
          width: 7.02rem;
          height: 0.02rem;
          background: #f8f8f8;
        }
        .buy-number {
          width: 7.02rem;
          display: flex;
          align-items: center;
          height: 1.16rem;
          justify-content: space-between;
          .label {
            font-size: 0.28rem;
            color: #1f2021;
          }
          .number {
            padding-right: 0.12rem;
            display: flex;
            width: 1.44rem;
            align-items: center;
            justify-content: space-between;
            .minus {
              width: 0.22rem;
              cursor: pointer;
              height: 0.22rem;
              display: flex;
              align-items: center;
              justify-content: center;
              .op {
                width: 0.18rem;
                background: #000000;
                height: 0.02rem;
                &.disabled {
                  background: #e7e7e7;
                }
              }
            }
            .add {
              width: 0.22rem;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 0.22rem;

              .op {
                background: #000000;
                height: 0.22rem;
                position: relative;
                width: 0.02rem;
                &::after {
                  background: #000000;
                  content: "";
                  height: 0.02rem;
                  left: 50%;
                  position: absolute;
                  transform: translateY(-50%) translateX(-50%);
                  top: 50%;
                  width: 0.22rem;
                }
                &.disabled {
                  background: #e7e7e7;
                  &::after {
                    background: #e7e7e7;
                  }
                }
              }
            }
            input {
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none !important;
                margin: 0;
              }
              width: 0.64rem;
              height: 0.45rem;
              border: none;
              font-size: 0.28rem;
              outline: none;
              text-align: center;
              background: #f5f6f8;
              &:focus {
                border: none;
              }
              &:active {
                border: none;
              }
            }
          }
        }
        .confirm-btn {
          cursor: pointer;
          margin-top: 0.1rem;
          width: 7.02rem;
          height: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.32rem;
          color: #ffffff;
          border-radius: 0.4rem;
          margin-bottom: 0.48rem;
          background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
        }
      }
    }
  }
}
</style>
