import { _axios } from '@/utils/axios'

export function getProducts(params) {
  return _axios({
    url: '/eshop/products',
    method: 'get',
    params
  })
}

export function getProductsDetail(params) {
  return _axios({
    url: '/eshop/products/detail',
    method: 'get',
    params
  })
}

export function getShopStatus(params) {
  return _axios({
    url: '/eshop/status',
    method: 'get',
    params
  })
}

export function newOrder(data) { // 生成订单
  return _axios({
    url: '/eshop/orders',
    method: 'put',
    data
  })
}

export function purchase(data) { // 下单
  return _axios({
    url: `/eshop/purchase`,
    method: 'post',
    data
  })
}

export function getOrderInfo(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: `/eshop/orders`,
    method: 'get',
    params
  })
}